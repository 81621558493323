




































































import { Vue, Component, Provide } from "vue-property-decorator";
import area from "@/utils/area";
import Nav from "@/components/header.vue";
import serve from '@/serve/mine'
import { Toast } from 'vant';
@Component({
  components: {
    Nav,
  },
})
export default class AddAddress extends Vue {
  name=""
  zip = ""
  @Provide() mobile = "";
  @Provide() code = "";
  @Provide() sendLoading = false;
  @Provide() time = 60;
  @Provide() password = "";
  @Provide() newpassword = "";
  @Provide() show = false;
  @Provide() areaList = area; // 省市区数据
  @Provide() address = ""; // 区域
  @Provide() details = ""; // 详细地址
  @Provide() QQ = ""; // qq  agree
  @Provide() fileList = []; // 营业执照
  @Provide() agree = false; // 同意协议
  @Provide() showDialog = false;
  private showPopup(): void {
    this.show = true;
  }
  private define(val: object): void {
    this.address = val[0].name + val[1].name + val[2].name;
    this.show = false;
  }
  async AddAddress() {
    const data = {
      area:this.address,
      details:this.details,
      name:this.name,
      phone:this.mobile
    }
   const res = await serve.addAddress(data)
   if(res.data.code === 0){
     Toast.success('新增成功')
     this.$router.push('/address')
   }else {
     Toast.fail(res.data.message)
   }
    this.showDialog = false;
  }
  private phoneValidator(val: string): boolean {
    return /^1[3-9]\d{9}$/.test(val);
  }
}
